import React, {useState, useEf, useEffect} from 'react';
import "./QuestionPage.css"
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';


function ProgressBar({ current, total}) {
  const progress =  ((current) / total) * 100;
  const isMobile = useMediaQuery({maxWidth: 768});
  return (
    <div className={isMobile ? 'progress-container-mob' : 'progress-container'}>
      <div className={isMobile ? 'progress-bar-mob' : 'progress-bar'} style={{ width: `${progress}%`, height: '20px' }}></div>
    </div>
  );
}

function Question_with_tabs({question, choices, tabs, setTabs, multi_allowed}) {
  const handleTabClick = (tab_content) => {
    if (tabs.includes(tab_content)) {
      // If tab is already selected, deselect it
      setTabs(tabs.filter(t => t !== tab_content));
    } else {
      setTabs([...tabs, tab_content]);
      if (multi_allowed == 0) 
        setTabs(tab_content)
      }
    };
  const isMobile = useMediaQuery({maxWidth: 768});
  
  function GradientButton({tab_content}) {
    let style = "";
    if (isMobile) {
      style = tabs.includes(tab_content) ? "gradient-button-selected-mob" : "gradient-button-unselected-mob";
    } else {
      style = tabs.includes(tab_content) ? "gradient-button-selected" : "gradient-button-unselected";  
    }
    return (
      <button key={tab_content} onClick={() => handleTabClick(tab_content)} 
          className={style}>{tab_content}</button>
    )
  }
  return (
    <div className='question-with-tabs'>
      <h2 className={isMobile? 'question-mob' : 'question'}>{question}</h2>
      <div className={isMobile ? 'buttons-box-mob': 'buttons-box'}>
        {choices.map(tab => (
          <GradientButton tab_content={tab} />
        ))}
      </div>
    </div>
  )
}
      

function Question({ content, info, onNext, onPrevious, tabs, setTabs, endOfPage, setEndOfPage, multi_allowed}) {
  const isMobile = useMediaQuery({maxWidth: 768});
  const grad_prev = isMobile? 'gradient-button-prev-mob' : 'gradient-button-prev'
  const grad_next = isMobile? 'gradient-button-next-mob' : 'gradient-button-next'
  return (
    <div className={isMobile ? 'question-box-mob' : 'question-box'}> 
      <Question_with_tabs question={content} choices={info} tabs={tabs} setTabs={setTabs} multi_allowed={multi_allowed}/>
      <div className='button-next-previous'>
          <button className={grad_prev} onClick={onPrevious}>Previous</button> 
          <div className='button-next'>
            <button className={grad_next}onClick={() => onNext(endOfPage, setEndOfPage)}>Skip</button> 
            <button className={grad_next} onClick={() => onNext(endOfPage, setEndOfPage)}>Next</button> 
          </div>
        </div>
    </div>
  );
}

const questions = [
  "Who is the gift for?",
//  "Are they?",
  "Are they?",
  "What type of gift do you have in mind?",
  "What are they into?",
  // Add more questions here
];

// 0 stands for single answer, 1 stands for multi answers allowed
const question_type = [0,0,1,1]

const choices = [
   ["mom", "dad", "sister", "brother", "grandma", "grandpa", "boyfriend", "girlfriend", "friend", "coworker", "yourself"],
// // ["friend", "family", "significant other", "co-worker", "yourself"] 
//  ["female", "male", "nonbinary"],
  ["baby", "kid", "teen", "adult", "elder"],
  ["practical", "cute", "romantic", "funny", "geeky", "relaxing", "sentimental", "luxurious", "creative"],
  ["cooking", "reading", "travel", "home decor", "coffee", "games", "geek",
"garden", "music", "art", "dogs", "cats"],
  // Add more questions here
];


function QuestionPage() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [endOfPage, setEndOfPage] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const handleNext = (endOfPage, setEndOfPage) => {
    if (currentQuestionIndex < answers.length) {
      let tmpAnswer = answers;
      tmpAnswer[currentQuestionIndex] = selectedTabs;
      setAnswers(tmpAnswer);
    }
    else {
      setAnswers([...answers, selectedTabs]);
    }
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    if (currentQuestionIndex == questions.length - 1) {
      setEndOfPage(true);

    }
    setSelectedTabs([]);
  };
  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setSelectedTabs(answers[currentQuestionIndex - 1]);
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };


  function DisplayByCondition() {
    const navigate = useNavigate();
    useEffect(() => {
      if (endOfPage) {
        navigate('/about', {state: {answers}})
      }  
    })
    const isMobile = useMediaQuery({maxWidth: 768});

    return (
      <div className='backGround'>
        <div className= {isMobile ? 'questions-container-mob' : 'questions-container'}>
        <div className={isMobile ? 'container-1bar-mob': 'container-1bar'}></div>
          <div className={isMobile ? 'container-2bar-mob': 'container-2bar'}>
            <ProgressBar current={currentQuestionIndex} total={questions.length}/>
            <Question content={questions[currentQuestionIndex]} 
            info = {choices[currentQuestionIndex]} onNext={handleNext} 
            onPrevious={handlePrevious} tabs={selectedTabs} setTabs={setSelectedTabs}
            endOfPage={endOfPage} setEndOfPage={setEndOfPage} multi_allowed = {question_type[currentQuestionIndex]}/> 
          </div>
        </div>
      </div>
    );
  
  }

  return(<DisplayByCondition/>);

}

export default QuestionPage;
