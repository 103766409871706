import React, { useState, useEffect } from 'react';
import './ChatPage.css';


function ChatPage() {
  const [messages, setMessages] = useState([]);  const sendMessage = async (msg) => {
    const url1 = 'https://new-chat-2.eba-3e6ft5ra.us-east-2.elasticbeanstalk.com/send-message';
    const url2 = 'https://www.send-message.giftsloveandfun.com/send-message'
    const history_url = 'https://www.send-message.giftsloveandfun.com/get-history'
    const local_url_2 = "http://127.0.0.1:8001/send-message"

    try {
      const response = await fetch(local_url_2, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: msg }),
      });
      const data = await response.json();
      console.log(data);
      const array = [[data['message'],'user'], [data['answer'],'ans']];
      setMessages([...messages, ...array]);
      console.log(messages);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  const local_url = "http://127.0.0.1:8001/send-message"

  const DisplayHistory= async() => {
    try {
      const response = await fetch(local_url,
        {method: "GET",
        headers: {
          "Accept": "application/json"
        }}); // Replace with your URL
      const result = await response.json();
      console.log(result);
      setMessages([]);
      let list = [];
      for (let i = 0; i < result.length; i++) {
        const user = result[i]['user'] === 'gpt' ? 'ans' : 'user';
        list = [...list,[result[i]['message'], user]];
      } 
      setMessages(list);
    } catch (error) {
      console.error('Error sending GET request:', error);
    }
  }

  const clearHistory = () => {
    fetch(local_url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // Handle success, e.g., display a success message
      })
      .catch((error) => {
        console.error('Error deleting database:', error);
        // Handle error, e.g., display an error message
      });
  };

  const clearPage = () => {
    setMessages([]);
  };

  return (
    <div className='big-container'>
      <button className='gradient-button' onClick={DisplayHistory}>check history</button>
      <button className='gradient-button222' onClick={clearPage}>clear page</button>
      <div className="chat-container">
        <div className="messages-area">
          {messages.map((msg, index) => 
            <div key={index}>
              <h5>{msg[1] === 'user'? "Question:" : "Answer:"}</h5>
              {msg[0].split("\n").map((i,key) => {
              return <div key={key}>{i}</div>;
          })}<br/>
            </div>)}
        </div>
        <InputArea onSend={sendMessage} />
      </div>
      <button className='gradient-button222' onClick={clearHistory}>clear History</button>
    </div>
  );
}

function InputArea({ onSend }) {
  const [input, setInput] = useState('');

  const handleSend = () => {
    onSend(input);
    setInput('');
  };

  return (
    <div className="input-area">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleSend()}
      />
      <button onClick={handleSend}>Send</button>
    </div>
  );
}

export default ChatPage;
