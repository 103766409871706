import './App.css';
import ChatPage from './ChatPage';
import HomePage from './HomePage';
import Product from './Products';

import { BrowserRouter,
  Routes,
  Route } from 'react-router-dom';
import QuestionPage from './QuestionPage';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<Product />} />
        <Route path="/questions" element={<QuestionPage />} />
        <Route path="/chatpage" element={<ChatPage />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
