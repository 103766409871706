import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, json, useLocation } from "react-router-dom";
import Marquee from "react-fast-marquee";
import combined_json from "./products/json_txt/1130_list.json";
import "./Products.css";
import 'bootstrap/dist/css/bootstrap.css';
import { useMediaQuery } from "react-responsive";


const Product = () => {
  let product = [];
  let id = [];
  let similarProducts = [];
  const {state} = useLocation();
  console.log(state);
  // const [product, setProduct] = useState([]);
  // const [similarProducts, setSimilarProducts] = useState([]);
  // const [id, setId] = useState([]);

  //const keys = ['title', 'images', 'price', 'id', 'url']

  const getProduct = async () => {
    let tmpProduct = [];
    let tmpId = [];
    const response = combined_json;      
    console.log(Object.keys(response))
    for (let j in Object.keys(response)) {
      const i = Object.keys(response)[j];
      if (!tmpId.includes(i)) {
        const data = response[i];
        if (data) {
          tmpProduct.push(data);
          tmpId.push(i);
        }
      }
    }
    // setProduct(tmpProduct);
    // setId(tmpId);
    product = tmpProduct;
    id = tmpId;
  };


  // const getProducts = async () => {
  //   setLoading(true);
  //   setLoading2(true);
  //   const data = combined_json;
  //   for (let r in data) {
  //     setProduct(data);  
  //   }
  // }
  // getProducts();

  
  const Loading = () => {
    return (
      <>
        <div className="container my-5 py-2">
          <div className="row">
            <div className="col-md-6 py-3">
              <Skeleton height={400} width={400} />
            </div>
            <div className="col-md-6 py-5">
              <Skeleton height={30} width={250} />
              <Skeleton height={90} />
              <Skeleton height={40} width={70} />
              <Skeleton height={50} width={110} />
              <Skeleton height={120} />
              <Skeleton height={40} width={110} inline={true} />
              <Skeleton className="mx-3" height={40} width={110} />
            </div>
          </div>
        </div>
      </>
    );
  };


  function downloadJsonStringAsFile(jsonString, fileName) {
    // Create a Blob from the JSON string
    console.log("i am here");
    const blob = new Blob([jsonString], { type: 'application/json' });
  
    // Create an anchor element and set the URL to the blob
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = fileName;
  
    // Append the link to the body, click it, and then remove it
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  
    // Release the blob URL
    URL.revokeObjectURL(url);
  }

  const ShowProducts = () => {
    getProduct();
    let tmpResult = {};
    let value;
    const [result, setResult] = useState("");

    const handleDownload = () => {
      console.log(tmpResult);
      downloadJsonStringAsFile(JSON.stringify(tmpResult), 'myData.json');
    };      

    const path = "./gender.txt";
    const handleChange = (event) => {
      let newValue = parseInt(event.target.value);
      const prod_id = event.target.id;
      // Allow only 1, 2, or 3
      if (newValue != 1 && newValue != 2) {
        newValue = 3;
      }
      tmpResult[prod_id] = newValue;
      console.log(tmpResult);
    };

    const isMobile = useMediaQuery({maxWidth: 768});

    return (
      <div className="main">
        <div className="top-bar">
          {/* <button className="wish-list-button">wish list</button> */}
        </div>
        <div className="big-box">
        {Object.entries(product).map(([key, item]) => (
          <div id={item.id} key={item.id} className={isMobile? "product-item-mob" : "product-item"}>
            <div className={isMobile? "image-container-mob" : "image-container"} key={item.id}>
              <a href={item.url} target="_blank">
              <img
                className={isMobile? "image-mob":"image"}
                src={item.images} 
                alt="Card"
              />
              </a>

            </div>
            <div className={isMobile? "price-title-container-mob" : "price-title-container"}>{item.title.substring(0,20)}</div>
            <div className={isMobile? "price-title-container-mob" : "price-title-container"}>${item.price}</div>
            <div className={isMobile? "button-container-mob" : "button-container"}>
              <a href={item.url} target="_blank">
              <button className={isMobile? "gradient-button-v2-mob":"gradient-button-v2"}>Go check it!</button>
              </a>
              </div>
        </div>
        ))} 
        </div>
      </div>
    );
  };

  const Loading2 = () => {
    return (
      <>
        <div className="my-4 py-4">
          <div className="d-flex">
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ShowSimilarProduct = () => {
    return (
      <>
        <div className="py-4 my-4">
          <div className="d-flex">
            {similarProducts.map((item) => {
              return (
                <div key={item.id} className="card mx-4 text-center">
                  <img
                    className="card-img-top p-3"
                    src={item.images}
                    alt="Card"
                    height={100}
                    width={100}
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {item.title.substring(0, 15)}...
                    </h5>
                  </div>
                  {/* <ul className="list-group list-group-flush">
                    <li className="list-group-item lead">${product.price}</li>
                  </ul> */}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <ShowProducts />
    </div>
  );
};

export default Product;
